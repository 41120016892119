.bounce_up {
  animation: bounce 1s infinite;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* tiptap */
.ProseMirror {
  /* outline: 1px solid #0066ff50 !important; */
  border-radius: 8px;
  overflow: hidden;
  padding-block: 10px;
  /* background: #cccccc30; */
  padding-inline: 8px;
}
/* style for tiptap input p when focused*/
.ProseMirror-focused {
  outline: none;
  /* outline: 2px solid #0066ff50 !important; */
}

/* style for tiptap input p */
.tiptap-box-paragraph {
  padding-block-end: 4px;
  color: #171d26;
  border: 0.1px solid transparent;
}
.tiptap-box-paragraph:hover {
  border-bottom-color: #7489aa20;
}

/* style for AN ATRRIBUTE in input p */
.attr-mention {
  background: #171d2610;
  /* color: #222; */
  /* color: #7489aa; */
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #7489aa;

  border-radius: 0.3rem;
  box-decoration-break: clone;
  display: inline-block;
  padding: 0rem 0.4rem;
  line-height: 1.4;
  /* cursor: pointer; */
}
.suggestion-style {
  background-color: yellow;
  color: #222;
  padding-inline: 0.5rem 0.2rem;
  border-radius: 0.4rem;
}
.mentions-items {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
  overflow: hidden;
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  max-height: 162px;
  /* overflow: hidden; */
  overflow-y: auto;
}

.mention-item {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  display: block;
  margin: 0;
  padding: 0.2rem 0.4rem;
  text-align: left;
  width: 100%;
}
.mention-item:hover {
  background: #171d2610;
}
.mention-is-selected {
  border-color: #000;
}

/* react-quil */
body .ql-toolbar.ql-snow {
  padding-block: 20px !important;
  background-color: rgba(0, 102, 255, 0.03);
  border-bottom: 1px solid #197eef1f !important;
  /* margin-bottom: 78px !important; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0px;
  row-gap: 10px;
}

body .ql-container.ql-snow {
  padding-top: 12px;
  /* max-width: 500px !important; */
  /* margin: 0 auto !important; */
}

/* @media (min-width: 1000px) {
  .ql-toolbar {
    margin-bottom: 84px !important;
  }
} */
